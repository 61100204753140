import { first, hasProperty, intersection } from '@mntn-dev/utilities'
import type { AnyRoute } from '../route.ts'

export const hasTabAttributes = <TTab extends string | string[]>(
  attributes: unknown
) => hasProperty<'tab', TTab>(attributes, 'tab')

export const findTab = <TTab extends string | string[]>(
  route: AnyRoute,
  // Only need to Provide available tabs when dealing with a route that has multiple possible/fallback tabs, as availableTabs are intersected with those tabs
  // E.g., When hiding the Organization tab for the intenral user, the organization route has [ organization, oranizations ] tabs.
  //       And since the organization tab is filtered out, the available organizations tab is used as it is the first intersection between the two sets.
  availableTabs?: TTab[]
) =>
  hasTabAttributes<TTab>(route.attributes)
    ? Array.isArray(route.attributes.tab)
      ? first(intersection(availableTabs, route.attributes.tab))
      : route.attributes.tab
    : undefined
