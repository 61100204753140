import {
  type PackageDomainSelectModel,
  PackageId,
  PackageSources,
  PackageUrn,
} from '@mntn-dev/domain-types'
import { calculateCostPlusMargin } from '@mntn-dev/finance'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockPackage = <
  Overrides extends Partial<
    PackageDomainSelectModel & { costMarginPercent: number }
  >,
>({
  costMarginPercent = 50,
  ...overrides
}: Overrides) =>
  MockModel<PackageDomainSelectModel>(
    ({
      packageId = PackageId(),
      packageUrn = PackageUrn(packageId),
      name = `Name ${packageId}`,
      description = `Description ${packageId}`,
      packageSource = getRandomItem(PackageSources),
      cost = 1,
      costPlusMargin = calculateCostPlusMargin(cost, costMarginPercent),
      status = 'draft',
    }) => {
      return {
        packageId,
        packageUrn,
        name,
        description,
        packageSource,
        cost,
        costPlusMargin,
        status,
      } satisfies PackageDomainSelectModel
    }
  )(overrides)
