import type { CostLike, PriceContext } from './types.ts'

export const getPriceValue = (
  context: PriceContext,
  { cost, costPlusMargin }: CostLike
) => {
  if (context === 'agency') {
    return cost
  }

  if (context === 'brand') {
    return costPlusMargin
  }
}
