import type { TupleToUnion } from 'type-fest'

export type ProjectPageTab = 'details' | 'activity' | 'internal'

export type DashboardPageTab =
  | 'all'
  | 'draft'
  | 'submitted'
  | 'in-progress'
  | 'completed'
  | 'archived'
  | 'offers'
  | 'rejected'

// The order in which these are listed are the order in which they appear in the tab group
export const accountPageTabs = [
  'profile',
  'users',
  'teams',
  'organization',
  'organizations',
  'billing',
] as const
export type AccountPageTab = TupleToUnion<typeof accountPageTabs>
