import type { OrganizationType } from '@mntn-dev/domain-types'
import type { PrivilegeKey, PrivilegeModel, RoleModel } from '../models.ts'
import {
  AgencyPrivilege,
  AllPrivilege,
  BrandPrivilege,
  CorePrivilege,
  InternalPrivilege,
} from './privilege.ts'
import { AgencyRole, BrandRole, InternalRole } from './roles.ts'

export const RoleMap: Record<OrganizationType, RoleModel> = {
  brand: BrandRole,
  agency: AgencyRole,
  internal: InternalRole,
}

export const PrivilegeMap: Record<PrivilegeKey, PrivilegeModel> = {
  core: CorePrivilege,
  brand: BrandPrivilege,
  agency: AgencyPrivilege,
  internal: InternalPrivilege,
  all: AllPrivilege,
}

export const getPrivileges = (organizationType: OrganizationType) =>
  RoleMap[organizationType].privileges

export const getPermissions = (privilegeKeys: PrivilegeKey[]) =>
  Array.from(
    new Set(privilegeKeys.flatMap((key) => PrivilegeMap[key].permissions))
  )
