import {
  type BillingProfileDomainSelectModel,
  BillingProfileId,
  OrganizationId,
} from '@mntn-dev/domain-types'
import { MockModel } from './mock-model.ts'

export const MockBillingProfile = MockModel<BillingProfileDomainSelectModel>(
  ({
    billingProfileId = BillingProfileId(),
    organizationId = OrganizationId(),
    billingSchedule = '100_up-front',
    currency = 'USD',
    invoiceTerms = 'none',
    defaultProfile = false,
  }) =>
    ({
      billingProfileId,
      organizationId,
      billingSchedule,
      currency,
      invoiceTerms,
      defaultProfile,
    }) satisfies BillingProfileDomainSelectModel
)
