import {
  TeamId,
  type OfferDomainSelectModel,
  OfferId,
  OfferStatuses,
  OfferUrn,
  ProjectId,
  UserId,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockOffer = MockModel<OfferDomainSelectModel>(
  ({
    offerId = OfferId(),
    offerUrn = OfferUrn(offerId),
    acceptorId = UserId(),
    agencyTeamId = TeamId(),
    projectId = ProjectId(),
    status = getRandomItem(OfferStatuses),
    statusTimestamp = new Date(),
  }) => {
    return {
      offerId,
      offerUrn,
      acceptorId,
      agencyTeamId,
      projectId,
      status,
      statusTimestamp,
    } satisfies OfferDomainSelectModel
  }
)
