import {
  type AgreementDomainSelectModel,
  AgreementId,
  AgreementTypes,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockAgreement = MockModel<AgreementDomainSelectModel>(
  ({ agreementId = AgreementId(), type = getRandomItem(AgreementTypes) }) => {
    return {
      agreementId,
      type,
      version: new Date(),
      title: `Agreement ${agreementId}`,
      link: 'https://example.com',
    } satisfies AgreementDomainSelectModel
  }
)
