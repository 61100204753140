import {
  type BidDomainSelectModel,
  BidId,
  BidStatuses,
  BidUrn,
  OrganizationId,
  ProjectId,
  TeamId,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockBid = MockModel<BidDomainSelectModel>(
  ({
    bidId = BidId(),
    bidUrn = BidUrn(bidId),
    projectId = ProjectId(),
    agencyOrganizationId = OrganizationId(),
    agencyTeamId = TeamId(),
    status = getRandomItem(BidStatuses),
    score = getRandomItem([0, 5, 10, 15, 20, 25, 30, 35, 40]),
  }) => {
    return {
      bidId,
      bidUrn,
      projectId,
      agencyOrganizationId,
      agencyTeamId,
      status,
      score,
    } satisfies BidDomainSelectModel
  }
)
