import {
  type ActivityDetails,
  type ActivityDomainSelectModel,
  ActivityId,
  type ActivityType,
  ActivityTypes,
  AgreementId,
  AgreementTypes,
  BidId,
  FeedId,
  FeedUrn,
  FileUrn,
  OfferId,
  PostProductionReviewBrandFeedbackSubmittedReviewStatusSchema,
  PostProductionReviewBrandFeedbackSubmittedRoundStatusSchema,
  PostProductionReviewMakerProposalSubmittedReviewStatusSchema,
  PostProductionReviewMakerProposalSubmittedRoundStatusSchema,
  PreProductionReviewBrandFeedbackSubmittedReviewStatusSchema,
  PreProductionReviewBrandFeedbackSubmittedRoundStatusSchema,
  PreProductionReviewMakerProposalSubmittedReviewStatusSchema,
  PreProductionReviewMakerProposalSubmittedRoundStatusSchema,
  ProjectStatusChangedActivityStatusSchema,
  ReviewId,
  RoundId,
  ThreadId,
  UserId,
} from '@mntn-dev/domain-types'
import {
  UnhandledUnionError,
  addBusinessDays,
  getRandomItem,
} from '@mntn-dev/utilities'

import { MockBid } from './mock-bid.ts'
import { MockFile } from './mock-file.ts'
import { MockModel } from './mock-model.ts'
import { MockOffer } from './mock-offer.ts'
import { MockProjectService } from './mock-project-service.ts'
import { MockProject } from './mock-project.ts'
import { MockTeam } from './mock-team.ts'

const BrandTeam = MockTeam({
  name: 'Brand Name',
})

const AgencyTeam = MockTeam({
  name: 'Agency Name',
})

const Project = MockProject({
  name: 'Project Name',
  brandTeamId: BrandTeam.teamId,
})

const ProjectService = MockProjectService({
  name: 'Project Service Name',
  projectId: Project.projectId,
})

const File = MockFile({
  name: 'Filename.jpg',
})

const Offer = MockOffer({
  projectId: Project.projectId,
  agencyTeamId: AgencyTeam.teamId,
})

const Bid = MockBid({
  projectId: Project.projectId,
  agencyOrganizationId: AgencyTeam.organizationId,
  agencyTeamId: AgencyTeam.teamId,
})

export const createActivityDetails = (
  activityType: ActivityType
): ActivityDetails<typeof activityType> => {
  switch (activityType) {
    case 'agreement_terms_accepted': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          type: getRandomItem(AgreementTypes),
          agreementId: AgreementId(),
          version: new Date(),
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
          },
        },
      }
    }

    case 'comment_created': {
      const feedId = FeedId()
      return {
        activityType,
        data: {
          text: 'Comment Text',
          target: { feed: { name: 'Feed Name', urn: FeedUrn(feedId) } },
        },
      }
    }

    case 'post_production_review_brand_feedback_submitted': {
      return {
        activityType,
        data: {
          reviewId: ReviewId(),
          projectId: Project.projectId,
          reviewStatus: getRandomItem(
            PostProductionReviewBrandFeedbackSubmittedReviewStatusSchema.options
          ),
          roundId: RoundId(),
          roundNumber: 1,
          roundStatus: getRandomItem(
            PostProductionReviewBrandFeedbackSubmittedRoundStatusSchema.options
          ),
          target: {
            project: {
              name: Project.name,
              urn: Project.projectUrn,
            },
          },
        },
      }
    }

    case 'post_production_review_maker_proposal_submitted': {
      return {
        activityType,
        data: {
          reviewId: ReviewId(),
          projectId: Project.projectId,
          reviewStatus: getRandomItem(
            PostProductionReviewMakerProposalSubmittedReviewStatusSchema.options
          ),
          roundId: RoundId(),
          roundNumber: 1,
          roundStatus: getRandomItem(
            PostProductionReviewMakerProposalSubmittedRoundStatusSchema.options
          ),
          target: {
            project: {
              name: Project.name,
              urn: Project.projectUrn,
            },
          },
        },
      }
    }

    case 'pre_production_brand_feedback_submitted': {
      return {
        activityType,
        data: {
          projectId: ProjectService.projectId,
          projectServiceId: ProjectService.projectServiceId,
          version: 1,
          approved: true,
          note: 'Brand Feedback',
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
            service: {
              name: ProjectService.name,
              urn: ProjectService.projectServiceUrn,
            },
          },
        },
      }
    }

    case 'pre_production_maker_proposal_submitted': {
      return {
        activityType,
        data: {
          projectId: ProjectService.projectId,
          projectServiceId: ProjectService.projectServiceId,
          version: 1,
          note: 'Maker Proposal',
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
            service: {
              name: ProjectService.name,
              urn: ProjectService.projectServiceUrn,
            },
          },
        },
      }
    }

    case 'pre_production_review_brand_feedback_submitted': {
      return {
        activityType,
        data: {
          reviewId: ReviewId(),
          projectId: ProjectService.projectId,
          projectServiceId: ProjectService.projectServiceId,
          reviewStatus: getRandomItem(
            PreProductionReviewBrandFeedbackSubmittedReviewStatusSchema.options
          ),
          roundId: RoundId(),
          roundNumber: 1,
          roundStatus: getRandomItem(
            PreProductionReviewBrandFeedbackSubmittedRoundStatusSchema.options
          ),
          target: {
            project: {
              name: Project.name,
              urn: Project.projectUrn,
            },
            service: {
              name: ProjectService.name,
              urn: ProjectService.projectServiceUrn,
            },
          },
        },
      }
    }

    case 'pre_production_review_maker_proposal_submitted': {
      return {
        activityType,
        data: {
          reviewId: ReviewId(),
          projectId: ProjectService.projectId,
          projectServiceId: ProjectService.projectServiceId,
          reviewStatus: getRandomItem(
            PreProductionReviewMakerProposalSubmittedReviewStatusSchema.options
          ),
          roundId: RoundId(),
          roundNumber: 1,
          roundStatus: getRandomItem(
            PreProductionReviewMakerProposalSubmittedRoundStatusSchema.options
          ),
          target: {
            project: {
              name: Project.name,
              urn: Project.projectUrn,
            },
            service: {
              name: ProjectService.name,
              urn: ProjectService.projectServiceUrn,
            },
          },
        },
      }
    }

    case 'project_bid_accepted': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
          },
        },
      }
    }

    case 'project_bid_rejected': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
          },
        },
      }
    }

    case 'project_bidding_closed': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          submittedBidIds: [BidId(), BidId()],
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
          },
        },
      }
    }

    case 'project_bidding_opened': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          brandTeamId: BrandTeam.teamId,
          biddingCloseDate: addBusinessDays(new Date(), 3),
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
            brandTeam: { name: BrandTeam.name, urn: BrandTeam.teamUrn },
          },
        },
      }
    }

    case 'project_bidding_successful': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          bidId: Bid.bidId,
          agencyTeamId: AgencyTeam.teamId,
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
            bid: { name: AgencyTeam.name, urn: Bid.bidUrn },
          },
        },
      }
    }

    case 'project_bidding_unsuccessful': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
          },
        },
      }
    }

    case 'project_created': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          target: {
            package: { name: 'Package Name', urn: Project.projectUrn },
          },
        },
      }
    }

    case 'project_file_added': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          fileId: File.fileId,
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
            file: { name: File.name, urn: FileUrn(File.fileId) },
          },
        },
      }
    }

    case 'project_not_matched': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
          },
        },
      }
    }

    case 'project_offer_expired': {
      return {
        activityType,
        data: {
          offerId: Offer.offerId,
          acceptorId: UserId(),
          target: { offer: { name: Project.name, urn: Offer.offerUrn } },
        },
      }
    }

    case 'project_offer_pending': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          offerId: Offer.offerId,
          acceptorId: UserId(),
          target: { offer: { name: Project.name, urn: Offer.offerUrn } },
        },
      }
    }

    case 'project_offers_sent': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          offerIds: [OfferId(), OfferId(), OfferId()],
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
          },
        },
      }
    }

    case 'project_service_added': {
      return {
        activityType,
        data: {
          projectId: ProjectService.projectId,
          projectServiceId: ProjectService.projectServiceId,
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
            service: {
              name: ProjectService.name,
              urn: ProjectService.projectServiceUrn,
            },
          },
        },
      }
    }

    case 'project_status_changed': {
      return {
        activityType,
        data: {
          projectId: Project.projectId,
          status: getRandomItem(
            ProjectStatusChangedActivityStatusSchema.options
          ),
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
          },
        },
      }
    }

    case 'service_note_added': {
      return {
        activityType,
        data: {
          projectId: ProjectService.projectId,
          projectServiceId: ProjectService.projectServiceId,
          noteType: 'brand',
          target: {
            project: { name: Project.name, urn: Project.projectUrn },
            service: {
              name: ProjectService.name,
              urn: ProjectService.projectServiceUrn,
            },
          },
        },
      }
    }

    default: {
      throw new UnhandledUnionError(activityType)
    }
  }
}

export const MockActivityDetails = <Type extends ActivityType>(
  activityType: Type
) => createActivityDetails(activityType) as ActivityDetails<Type>

export const MockActivity = MockModel<ActivityDomainSelectModel>(
  ({
    activityId = ActivityId(),
    feedId = FeedId(),
    threadId = ThreadId(1),
    actorId = UserId(),
    timestamp = new Date(),
    details = MockActivityDetails(getRandomItem(ActivityTypes)),
  }) => {
    return {
      activityId,
      feedId,
      threadId,
      actorId,
      timestamp,
      details,
    } satisfies ActivityDomainSelectModel
  }
)
