import type { RoleModel } from '../models.ts'

export const BrandRole: RoleModel = {
  name: 'Brand',
  privileges: ['core', 'brand'],
}

export const AgencyRole: RoleModel = {
  name: 'Agency',
  privileges: ['core', 'agency'],
}

export const InternalRole: RoleModel = {
  name: 'Internal',
  privileges: ['core', 'internal'],
}

export const SystemRole: RoleModel = {
  name: 'System',
  privileges: ['all'],
}

export const AllRoles = [BrandRole, AgencyRole, InternalRole, SystemRole]
