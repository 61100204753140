import {
  DeliverableReviewLevels,
  type ServiceDomainSelectModel,
  ServiceId,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockService = MockModel<ServiceDomainSelectModel>(
  ({
    serviceId = ServiceId(),
    name = `Name ${serviceId}`,
    description = `Description ${serviceId}`,
    preProductionReview = getRandomItem([true, false]),
    postProductionReview = getRandomItem(DeliverableReviewLevels),
    status = 'draft',
  }) => {
    return {
      serviceId,
      name,
      description,
      preProductionReview,
      postProductionReview,
      status,
    } satisfies ServiceDomainSelectModel
  }
)
