import {
  type OrganizationDomainSelectModel,
  OrganizationId,
  OrganizationTypes,
  OrganizationUrn,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockOrganization = MockModel<OrganizationDomainSelectModel>(
  ({
    organizationId = OrganizationId(),
    organizationUrn = OrganizationUrn(organizationId),
    organizationType = getRandomItem(OrganizationTypes),
    name = `Organization ${organizationId}`,
    onboarding = {
      status: 'onboarding',
      stepsCompleted: [],
    },
  }) => {
    return {
      organizationId,
      organizationUrn,
      organizationType,
      name,
      onboarding,
    } satisfies OrganizationDomainSelectModel
  }
)
