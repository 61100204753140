import type {
  ActivityId,
  BidId,
  DeliverableId,
  FileId,
  OfferId,
  OrganizationId,
  PackageId,
  ProjectId,
  ProjectServiceId,
  TeamId,
  UserId,
} from '@mntn-dev/domain-types'
import type { EmptyObject } from 'type-fest'
import type { AccountRouteAttributes } from './attributes/types.ts'
import { register } from './register.ts'
import type {
  DashboardSortBy,
  SortDirection,
  UsersSortBy,
} from './types/sort-types.ts'
import type { DashboardPageTab, ProjectPageTab } from './types/tab-types.ts'

const routes = [
  // Unprotected

  register('/auth/logout').toRoute({}),

  register('/login').toRoute<
    EmptyObject,
    { emailAddress?: string; returnTo?: string }
  >({}),

  // Protected

  register('/')
    .protected()
    .toRoute({
      name: 'dashboard',
    }),

  register('/account')
    .protected()
    .toRoute<EmptyObject, EmptyObject, AccountRouteAttributes>({
      name: 'account',
    }),

  register('/account/finance')
    .protected()
    .toRoute<EmptyObject, EmptyObject, AccountRouteAttributes>({
      name: 'billing',
      tab: 'billing',
    }),

  register('/account/finance/billing/add-method').protected().toRoute({}),

  register('/account/teams')
    .protected()
    .toRoute<EmptyObject, { teamId: TeamId }, AccountRouteAttributes>({
      name: 'teams',
      tab: 'teams',
    }),

  register('/account/profile')
    .protected()
    .toRoute<EmptyObject, EmptyObject, AccountRouteAttributes>({
      name: 'profile',
      tab: 'profile',
    }),

  register('/account/organizations')
    .protected()
    .authorization(({ permissions }) =>
      permissions.canAdministerCustomerOrganizations()
    )
    .toRoute<EmptyObject, EmptyObject, AccountRouteAttributes>({
      tab: 'organizations',
      name: 'organizations',
    }),

  register('/account/organizations/:organizationId')
    .protected()
    .toRoute<
      { organizationId: OrganizationId },
      EmptyObject,
      AccountRouteAttributes
    >({
      tab: ['organization', 'organizations'],
      name: 'organization',
    }),

  register('/account/organizations/:organizationId/teams')
    .protected()
    .toRoute<
      { organizationId: OrganizationId },
      { teamId: TeamId },
      AccountRouteAttributes
    >({
      name: 'teams',
      tab: 'teams',
    }),

  register('/account/organizations/:organizationId/teams/:teamId')
    .protected()
    .toRoute<
      { organizationId: OrganizationId; teamId: TeamId },
      EmptyObject,
      AccountRouteAttributes
    >({
      name: 'teams',
      tab: 'teams',
    }),

  register('/account/organizations/:organizationId/users')
    .protected()
    .toRoute<
      { organizationId: OrganizationId },
      EmptyObject,
      AccountRouteAttributes
    >({ name: 'users', tab: 'users' }),

  register('/account/organizations/:organizationId/users/:userId')
    .protected()
    .toRoute<{ organizationId: OrganizationId; userId: UserId }>({}),

  register('/dashboard')
    .protected([['project:view']])
    .toRoute<
      EmptyObject,
      {
        search?: string
        sortBy?: DashboardSortBy
        sortDir?: SortDirection
        tab?: DashboardPageTab
      }
    >({}),

  register('/offers').protected().toRoute({}),

  register('/offers/:offerId').protected().toRoute<{ offerId: OfferId }>({}),

  register('/packages').protected().toRoute({}),

  register('/packages/:packageId')
    .protected()
    .toRoute<{ packageId: PackageId }>({}),

  register('/packages/services').protected().toRoute({}),

  register('/projects').protected().toRoute({}),

  register('/projects/:projectId')
    .protected()
    .toRoute<
      { projectId: ProjectId },
      { tab?: ProjectPageTab; activityId?: ActivityId }
    >({}),

  register('/projects/:projectId/bid/:bidId')
    .protected()
    .toRoute<{ projectId: ProjectId; bidId: BidId }>({}),

  register('/projects/:projectId/bid/:bidId/edit')
    .protected()
    .toRoute<{ projectId: ProjectId; bidId: BidId }>({}),

  register('/projects/:projectId/services/:projectServiceId')
    .protected()
    .toRoute<{
      projectId: ProjectId
      projectServiceId: ProjectServiceId
    }>({}),

  register('/projects/:projectId/match')
    .protected()
    .toRoute<{ projectId: ProjectId }>({}),

  register('/projects/:projectId/match/maker/:userId').protected().toRoute<{
    projectId: ProjectId
    userId: UserId
  }>({}),

  register('/projects/:projectId/browse').protected().toRoute<{
    projectId: ProjectId
  }>({}),

  register('/projects/:projectId/review/post-production/:deliverableId')
    .protected()
    .toRoute<{
      projectId: ProjectId
      deliverableId: DeliverableId
    }>({}),

  register('/projects/:projectId/files').protected().toRoute<{
    projectId: ProjectId
  }>({}),

  register('/projects/:projectId/files/:fileId').protected().toRoute<{
    projectId: ProjectId
    fileId: FileId
  }>({}),

  register('/projects/new').protected().toRoute({}),

  register('/users').toRoute<
    EmptyObject,
    { search?: string; sortBy?: UsersSortBy; sortDir?: SortDirection }
  >({}),

  register('/users/:userId').protected().toRoute<{ userId: UserId }>({}),

  // Testing

  register('/foo')
    .protected([['project:view']])
    .toRoute<EmptyObject, { baz: string }>({}),

  register('/foo/:fooId').toRoute<{ fooId: string }, { baz: string }>({}),

  register('/foo/:fooId/bar/:barId').toRoute<
    { fooId: string; barId: string },
    { baz: string; qux: string; corge?: string }
  >({}),
]

export default routes
