import { SessionIdSchema } from '@mntn-dev/domain-types'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { Auth0SessionSchema } from './auth0-session.ts'
import { SessionPrincipalSchema } from './principal.ts'

export const SessionSchema = Auth0SessionSchema.extend({
  principal: SessionPrincipalSchema,
  sessionId: SessionIdSchema,
  requestPathname: z.string().optional(),
})

export type Session = ZodSimplify<typeof SessionSchema>

export const isAuthSessionWithPrincipal = (
  value: unknown
): value is Session => {
  return SessionSchema.safeParse(value).success
}

export const assertAuthSessionWithPrincipal = (
  value: unknown,
  message: string
): asserts value is Session => {
  try {
    SessionSchema.parse(value)
  } catch (error) {
    throw new Error(
      `${message}: ${error instanceof Error ? error.message : String(error)}`
    )
  }
}
