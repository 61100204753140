import {
  SubjectId,
  UserId,
  type WatchDomainSelectModel,
  WatchEventTypes,
  WatchId,
  WatchNotificationTypes,
  WatchPreferenceTypes,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockWatch = MockModel<WatchDomainSelectModel>(
  ({
    watchId = WatchId(),
    userId = UserId(),
    subjectId = SubjectId(),
    watchEventType = getRandomItem(WatchEventTypes),
    watchPreferenceType = getRandomItem(WatchPreferenceTypes),
    watchNotificationType = getRandomItem(WatchNotificationTypes),
  }) => {
    return {
      watchId,
      userId,
      subjectId,
      watchEventType,
      watchPreferenceType,
      watchNotificationType,
    } satisfies WatchDomainSelectModel
  }
)
