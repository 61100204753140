import { convertCentsToDollars } from './convert-cents-to-dollars.ts'
import { convertDollarsToCents } from './convert-dollars-to-cents.ts'
import { getPriceValue } from './get-price-value.ts'
import type { CostLike, PriceContext } from './types.ts'

export const sumPrice = (
  context: PriceContext,
  costLikeEntities: CostLike[]
) => {
  const result = costLikeEntities.reduce<number | undefined>(
    (acc, costLikeEntity) => {
      const value = getPriceValue(context, costLikeEntity)
      return value !== undefined
        ? (acc ?? 0) + convertDollarsToCents(value)
        : acc
    },
    undefined
  )

  return result && convertCentsToDollars(result)
}
