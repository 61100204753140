import type { UserDomainQueryModel } from '@mntn-dev/domain-types'
import type { Auth0User } from './auth0-session.ts'

type UserInfo = Pick<
  UserDomainQueryModel,
  | 'userId'
  | 'emailAddress'
  | 'firstName'
  | 'lastName'
  | 'displayName'
  | 'avatar'
>

export const getAuth0User = (user: UserInfo): Auth0User => ({
  sub: user.userId,
  email: user.emailAddress,
  given_name: user.firstName,
  family_name: user.lastName,
  name: user.displayName,
  nickname: user.displayName,
  picture: user.avatar?.fileId,
})
