import { NameSchema } from '@mntn-dev/domain-types'
import { z } from 'zod'
import { EnumBuilder, type ZodSimplify } from '../../utility-types/src/index.ts'
import { Permissions } from './types/permissions.ts'

export const PermissionsSchema = z.array(z.enum(Permissions))

export const [PrivilegeKeys, PrivilegeKeySchema, PrivilegeKeyEnum] =
  EnumBuilder('core', 'brand', 'agency', 'internal', 'all')
export type PrivilegeKey = ZodSimplify<typeof PrivilegeKeySchema>

export const PrivilegeModelSchema = z.object({
  name: NameSchema,
  key: PrivilegeKeySchema,
  permissions: PermissionsSchema,
})

export type PrivilegeModel = ZodSimplify<typeof PrivilegeModelSchema>

export const RoleModelSchema = z.object({
  name: NameSchema,
  privileges: z.array(PrivilegeKeySchema),
})

export type RoleModel = ZodSimplify<typeof RoleModelSchema>
