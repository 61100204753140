import {
  TagCategories,
  type TagDomainSelectModel,
  TagId,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockTag = MockModel<TagDomainSelectModel>(
  ({
    tagId = TagId(),
    category = getRandomItem(TagCategories),
    value = `Tag ${tagId}`,
  }) => {
    return {
      tagId,
      category,
      value,
    } satisfies TagDomainSelectModel
  }
)
