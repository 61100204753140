import type {
  AuthenticatedSession,
  AuthorizedSession,
  AuthorizedSessionJSON,
  Principal,
} from '@mntn-dev/auth-types'

import { createPermissions } from './permissions/create-permissions.ts'

export const authorizeSessionFromPrincipal = (
  session: AuthenticatedSession | AuthorizedSession,
  principal: Principal
): AuthorizedSession => {
  const authorizedSession: AuthorizedSession = {
    ...session,
    principal: {
      ...session.principal,
      ...principal,
      ...createPermissions(principal),
    },
  } as const

  return authorizedSession
}

export const rehydrateAuthorizedSession = (
  session: AuthorizedSession | AuthorizedSessionJSON
): AuthorizedSession => {
  const authorizedSession: AuthorizedSession = {
    ...session,
    principal: {
      ...session.principal,
      ...createPermissions(session.principal),
    },
  } as const

  return authorizedSession
}
