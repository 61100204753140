import type { PrivilegeModel } from '../models.ts'
import { AllPermissions } from '../types/permissions.ts'

export const CorePrivilege: PrivilegeModel = {
  name: 'Core',
  key: 'core',
  permissions: [
    'activity:create',
    'activity:view',
    'file:upload',
    'project:view',
    'organization:administer',
    'team:administer',
    'user:administer',
  ],
}

export const BrandPrivilege: PrivilegeModel = {
  name: 'Brand',
  key: 'brand',
  permissions: [
    'cost-plus-margin:view',
    'internal-pricing-note:view',
    'project:archive',
    'project:comment',
    'project:create',
    'project:delete',
    'project:edit',
    'project:submit',
    'review-feedback:update',
  ],
}

export const AgencyPrivilege: PrivilegeModel = {
  name: 'Agency',
  key: 'agency',
  permissions: [
    'cost:view',
    'offer:accept',
    'offer:decline',
    'offer:view',
    'project:comment',
    'project:complete',
    'review-proposal:update',
  ],
}

export const InternalPrivilege: PrivilegeModel = {
  name: 'Internal',
  key: 'internal',
  permissions: [
    'cost:view',
    'cost-plus-margin:view',
    'internal-pricing-note:view',
    'internal-updates:subscribe',
    'offer:view',
    'package:administer',
    'package:view',
    'project:administer',
    'project:complete',
    'project:edit',
    'organization:administer',
    'team:administer',
    'user:administer',
    'watch:administer',
    'match:edit',
    'match:remove',
    'match:view',
  ],
}

export const AllPrivilege: PrivilegeModel = {
  name: 'All',
  key: 'all',
  permissions: AllPermissions,
}

export const AllPrivileges = [
  CorePrivilege,
  AgencyPrivilege,
  BrandPrivilege,
  InternalPrivilege,
  AllPrivilege,
]
