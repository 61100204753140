import {
  OrganizationId,
  OrganizationTypes,
  type TeamDomainSelectModel,
  TeamId,
  TeamUrn,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockTeam = MockModel<TeamDomainSelectModel>(
  ({
    teamId = TeamId(),
    teamUrn = TeamUrn(teamId),
    organizationType = getRandomItem(OrganizationTypes),
    organizationId = OrganizationId(),
    name = `Team ${teamId}`,
    avatarFileId,
  }) => {
    return {
      teamId,
      teamUrn,
      organizationType,
      organizationId,
      name,
      avatarFileId,
    } satisfies TeamDomainSelectModel
  }
)
