import {
  OrganizationId,
  OrganizationTypes,
  TeamId,
  UserId,
} from '@mntn-dev/domain-types'

import { MockModel, MockUser } from '@mntn-dev/domain-mocks'
import { getRandomItem } from '@mntn-dev/utilities'
import type { Principal, SessionPrincipal } from './principal.ts'

export const MockPrincipal = MockModel<Principal>(
  ({
    userId = UserId(),
    emailAddress = `user+${userId}@example.com`,
    organizationId = OrganizationId(),
    organizationType = getRandomItem(OrganizationTypes),
    teamIds = [],
    privileges = [],
  }) => {
    return {
      userId,
      emailAddress,
      organizationId,
      organizationType,
      teamIds,
      privileges,
      user: MockUser({ userId, emailAddress, organizationId }),
    } satisfies Principal
  }
)

export const MockSessionPrincipal = MockModel<SessionPrincipal>(
  ({
    userId = UserId(),
    emailAddress = `user+${userId}@example.com`,
    organizationId = OrganizationId(),
    organizationType = getRandomItem(OrganizationTypes),
    teamIds = [TeamId()],
    scopes = [],
    privileges = [],
  }) => {
    return {
      userId,
      emailAddress,
      organizationId,
      organizationType,
      teamIds,
      scopes,
      privileges,
      user: MockUser({
        userId,
        emailAddress,
        organizationId,
        organizationType,
      }),
    } satisfies SessionPrincipal
  }
)
