import type { Permission } from '@mntn-dev/authorization-types'
import { runningOnServer } from '@mntn-dev/utilities'
import type { AnyValue, ExactObject } from '@mntn-dev/utility-types'
import type { EmptyObject } from 'type-fest'
import type { Authorization } from './types/authorization.ts'
import type { UriKind } from './types/url-types.ts'

type Permissions = Permission[][]
export type RouteAttributes = { permissions?: Permissions }

export type RouteRegistration<
  TPattern extends string,
  TRouteParams extends {} = EmptyObject,
  TQueryParams extends {} = EmptyObject,
  TRouteAttributes extends {} = EmptyObject,
> = {
  pattern: TPattern
  segments: string[]
  params: (
    routeParams: ExactObject<TRouteParams>
  ) => RouteRegistration<TPattern, TRouteParams, TQueryParams, TRouteAttributes>
  attributes: TRouteAttributes
  permissions: Permissions
  query: (
    queryParams: ExactObject<TQueryParams>
  ) => RouteRegistration<TPattern, TRouteParams, TQueryParams, TRouteAttributes>
  toRelativeUrl: () => string
  toAbsoluteUrl: () => string
  toUrl: (uriKind: UriKind) => string
  isMatch: (otherPath: string) => boolean
  isProtected: boolean
  isAuthorized: (options: Authorization) => boolean
  isTest: boolean
  getRoot: () => string
  parseRouteParams: (path: string) => TRouteParams | EmptyObject
}

export function register<TPattern extends string>(pattern: TPattern) {
  // route registration state
  const registrationState: {
    permissions: Permissions
    isProtected: boolean
    isTest: boolean
    isAuthorized: (options: Authorization) => boolean
  } = {
    permissions: [],
    isProtected: false,
    isTest: false,
    isAuthorized: () => true,
  }

  const registration = {
    protected: (withPermissions?: Permissions) => {
      registrationState.permissions = withPermissions ?? []
      registrationState.isProtected = true
      return registration
    },
    authorization: (isAuthorized: (options: Authorization) => boolean) => {
      registrationState.isAuthorized = isAuthorized
      return registration
    },
    test: () => {
      registrationState.isTest = true
      return registration
    },
    toRoute: <
      TRouteParams extends {} = EmptyObject,
      TQueryParams extends {} = EmptyObject,
      TRouteAttributes extends {} = EmptyObject,
    >(
      attributes: TRouteAttributes
    ): [
      TPattern,
      () => RouteRegistration<TPattern, TRouteParams, TQueryParams>,
    ] => {
      const routeBuilder: () => RouteRegistration<
        TPattern,
        TRouteParams,
        TQueryParams,
        TRouteAttributes
      > = () => {
        let path: string = pattern
        const toRelativeUrl = () => path
        const toAbsoluteUrl = () =>
          // TODO: Move getClientBaseUrl function from magicsky to somewhere app & env common and hook into it from here.
          // Maybe a new package called app-common?
          // Ensure Storybook Activity still works. It seems to have issue with env from @mntn-dev/env
          `${runningOnServer() ? process.env.BASE_URL : window.location.origin}${path}`

        const isMatch = (otherPath: string) => {
          const otherPathSegments = otherPath.split('/')
          const thisPathSegments = path.split('/')

          if (otherPathSegments.length !== thisPathSegments.length) {
            return false
          }

          return thisPathSegments.reduce<boolean>((result, _, index) => {
            if (result) {
              return (
                thisPathSegments[index]?.startsWith(':') ||
                otherPathSegments[index] === thisPathSegments[index]
              )
            }
            return false
          }, true)
        }

        const route: RouteRegistration<
          TPattern,
          TRouteParams,
          TQueryParams,
          TRouteAttributes
        > = {
          attributes,
          pattern,
          segments: pattern.split('/').filter(Boolean),
          params: (routeParams) => {
            path = Object.keys(routeParams).reduce<string>((path, key) => {
              return path.replace(
                `:${key}`,
                `${routeParams[key as keyof ExactObject<TRouteParams>]}`
              )
            }, path)

            return route
          },
          query: (queryParams) => {
            path = `${path}?${new URLSearchParams(
              Object.entries(queryParams)
                .map(([key, value]) => [
                  key,
                  value === undefined ? '' : String(value),
                ])
                .sort()
            ).toString()}`

            return route
          },
          ...registrationState,
          toRelativeUrl,
          toAbsoluteUrl,
          toUrl: (uriKind) =>
            uriKind === 'relative' ? toRelativeUrl() : toAbsoluteUrl(),
          isMatch,
          getRoot: () => `/${pattern.split('/')[1] ?? ''}`,
          parseRouteParams: (actualPath) => {
            if (isMatch(actualPath)) {
              const actualPathSegments = actualPath.split('/')
              const routePathSegments = path.split('/')

              return actualPathSegments.reduce<TRouteParams>(
                (routeParams, _, index) => {
                  const routePathSegment = routePathSegments[index]

                  if (routePathSegment?.startsWith(':')) {
                    const routePathSegmentKey = routePathSegment.slice(
                      1
                    ) as keyof TRouteParams
                    routeParams[routePathSegmentKey] = actualPathSegments[
                      index
                    ] as AnyValue
                  }

                  return routeParams
                },
                {} as TRouteParams
              )
            }

            return {}
          },
        }

        return route
      }

      return [pattern, routeBuilder]
    },
  }

  return registration
}
