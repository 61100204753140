import {
  type FileArea,
  OrganizationId,
  SessionId,
  TeamId,
  UserId,
  UserUrn,
} from '@mntn-dev/domain-types'
import type {
  Merge,
  OverrideProperties,
  SetOptional,
  Simplify,
} from 'type-fest'
import type { Auth0Session, Auth0User } from './auth0-session.ts'
import type { SessionPrincipal } from './principal.ts'

type AuthenticatedSession = Simplify<
  SetOptional<
    OverrideProperties<
      Auth0Session,
      { user: SetOptional<Auth0User, 'sub'> | Record<string, never> }
    >,
    'internal' | 'tokenSet'
  > & {
    sessionId: SessionId
    principal: SessionPrincipal
    accessTokenExpiresAt?: number
    accessToken?: string
  }
>

/**
 * UnauthenticatedSession
 */

type UnauthenticatedSession =
  | OverrideProperties<
      SetOptional<
        AuthenticatedSession,
        'principal' | 'internal' | 'tokenSet' | 'user'
      >,
      { user?: SetOptional<Auth0User, 'sub'> | Record<string, never> }
    >
  | null
  | undefined

/**
 * AuthorizedSession
 */

type Permissions = {
  canAcceptOffer: () => boolean
  canAdministerPackages: () => boolean
  canAdministerProjects: () => boolean
  canAdministerWatches: () => boolean
  canArchiveProject: () => boolean
  canCommentOnProject: () => boolean
  canCompleteProject: () => boolean
  canCreateActivity: () => boolean
  canCreateProject: () => boolean
  canCreateReview: () => boolean
  canCreateWatch: () => boolean
  canDeclineOffer: () => boolean
  canDeleteProject: () => boolean
  canDeleteWatch: () => boolean
  canEditAgency: () => boolean
  canEditMatch: () => boolean
  canEditProject: () => boolean
  canRemoveMatch: () => boolean
  canSubmitProject: () => boolean
  canSubscribeToInternalUpdates: () => boolean
  canUpdateReviewFeedback: () => boolean
  canUpdateReviewProposal: () => boolean
  canUpdateWatch: () => boolean
  canUploadFile: (fileType: FileArea) => boolean
  canViewActivity: () => boolean
  canViewAgency: () => boolean
  canViewAllWatches: () => boolean
  canViewPackages: () => boolean
  canViewBrand: () => boolean
  canViewInternalPricingNote: () => boolean
  canViewMatch: () => boolean
  canViewOffer: () => boolean
  canViewProject: () => boolean
  canViewWatch: () => boolean
  canViewCost: () => boolean
  canViewCostPlusMargin: () => boolean

  // Organizations
  canAdministerCustomerOrganizations: () => boolean // Can you administer any customer organization? For internal admins
  canAdministerOwnOrganization: () => boolean // Can you administer your own organization? For organization admins and internal super admins

  // Teams
  canAdministerCustomerTeams: () => boolean // Can you administer any customer teams? For internal admins
  canAdministerOwnOrganizationTeams: () => boolean // Can you administer any team in your organization? For organization admins
  canAdministerOwnTeam: () => boolean // Can you administer the team that you're on? For team admins

  // Users
  canAdministerCustomerUsers: () => boolean // Can you administer any customer users? For internal admins
  canAdministerOwnOrganizationUsers: () => boolean // Can you administer any user in your organization? For organization admins
  canAdministerOwnTeamUsers: () => boolean // Can you administer any user on your team? For team admins

  // DEPRECATED
  canAdministerUsers: () => boolean
}

type WithPermissions<T> = Merge<T, Permissions>

type AuthorizedSessionJSON = AuthenticatedSession

type AuthorizedSession = OverrideProperties<
  AuthorizedSessionJSON,
  {
    principal: WithPermissions<AuthorizedSessionJSON['principal']>
  }
>

type SystemSessionJson = AuthorizedSessionJSON
type SystemSession = AuthorizedSession

/**
 * Example of AuthorizedSession below
 */
const permissions = {
  canViewProject: () => true,
} as Permissions

const _authorizedSessionExample: AuthorizedSession = {
  sessionId: SessionId(),
  principal: {
    userId: UserId(),
    emailAddress: 'test@mountain.com',
    teamIds: [TeamId()],
    organizationId: OrganizationId(),
    organizationType: 'brand',
    privileges: ['core', 'brand'],
    user: {
      userId: UserId(),
      userUrn: UserUrn(UserId()),
      emailAddress: 'test@mountain.com',
      organizationId: OrganizationId(),
      organizationType: 'brand',
      firstName: 'Some',
      lastName: 'User',
      displayName: 'Some User',
      initials: 'SU',
      authConnection: 'Username-Password-Authentication',
      isActive: true,
      signUpTimestamp: new Date(),
    },
    scopes: [],
    ...permissions,
  },
  user: {
    sub: '',
  },
}

export type {
  UnauthenticatedSession,
  AuthenticatedSession,
  AuthorizedSession,
  AuthorizedSessionJSON,
  SystemSession,
  SystemSessionJson,
  Permissions,
}
