import {
  DeliverableReviewLevels,
  PackageId,
  PackageServiceId,
  ProjectId,
  type ProjectServiceDomainSelectModel,
  ProjectServiceId,
  ProjectServiceUrn,
  ServiceId,
  ServiceTypes,
} from '@mntn-dev/domain-types'
import { calculateCostPlusMargin } from '@mntn-dev/finance'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockProjectService = MockModel<ProjectServiceDomainSelectModel>(
  ({
    projectServiceId = ProjectServiceId(),
    projectServiceUrn = ProjectServiceUrn(projectServiceId),
    projectId = ProjectId(),
    packageServiceId = PackageServiceId(),
    packageId = PackageId(),
    serviceType = getRandomItem(ServiceTypes),
    count = 1,
    cost,
    costMarginPercent = 50,
    costPlusMargin = cost && calculateCostPlusMargin(cost, costMarginPercent),
    serviceId = ServiceId(),
    name = `Name ${projectServiceId}`,
    description = `Description ${projectServiceId}`,
    preProductionReview = getRandomItem([true, false]),
    postProductionReview = getRandomItem(DeliverableReviewLevels),
  }) => {
    return {
      projectServiceId,
      projectServiceUrn,
      projectId,
      packageId,
      packageServiceId,
      serviceType,
      count,
      cost,
      costMarginPercent,
      costPlusMargin,
      serviceId,
      name,
      description,
      preProductionReview,
      postProductionReview,
    } satisfies ProjectServiceDomainSelectModel
  }
)
