import type {
  AuthorizedSession,
  SessionPrincipal,
  SystemSession,
  SystemSessionJson,
} from '@mntn-dev/auth-types'
import {
  OrganizationId,
  type UserDomainSelectModel,
  UserId,
  UserUrn,
} from '@mntn-dev/domain-types'
import { createPermissions } from './permissions/create-permissions.ts'

export const SystemUserId = UserId.Empty
export const SystemOrganizationId = OrganizationId.Empty

export const SystemUser: UserDomainSelectModel = {
  emailAddress: 'system@quickframe.com',
  firstName: 'System',
  lastName: 'User',
  displayName: 'System User',
  initials: 'SU',
  authConnection: 'Username-Password-Authentication',
  isActive: true,
  userId: SystemUserId,
  userUrn: UserUrn(SystemUserId),
  organizationId: SystemOrganizationId,
  organizationType: 'internal',
  signUpTimestamp: new Date(),
}

export type SystemUser = typeof SystemUser

export const SystemPrincipal: SessionPrincipal = {
  isSystem: true,
  userId: SystemUser.userId,
  emailAddress: SystemUser.emailAddress,
  organizationId: SystemUser.organizationId,
  teamIds: [],
  organizationType: 'internal',
  user: SystemUser,
  scopes: [],
  privileges: ['all'],
}

export const isSystemUser = (
  user: Pick<UserDomainSelectModel, 'userId' | 'organizationType'>
): user is SystemUser => {
  const systemUser = user as SystemUser
  return (
    systemUser?.userId === SystemUser.userId &&
    systemUser?.organizationType === SystemUser.organizationType
  )
}

export const systemSessionFromAuthorizedSession = (
  session: AuthorizedSession
): SystemSession => ({
  ...session,
  principal: {
    ...session.principal,
    ...SystemPrincipal,
    ...createPermissions(SystemPrincipal),
  },
})

export const rehydrateSystemSession = (
  session: SystemSession | SystemSessionJson
): SystemSession => {
  const systemSession: SystemSession = {
    ...session,
    principal: {
      ...session.principal,
      ...createPermissions(session.principal),
    },
  } as const

  return systemSession
}
