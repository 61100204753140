import {
  ReviewId,
  type RoundDomainSelectModel,
  RoundId,
} from '@mntn-dev/domain-types'
import { MockModel } from './mock-model.ts'

export const MockRound = MockModel<RoundDomainSelectModel>(
  ({
    roundId = RoundId(),
    reviewId = ReviewId(),
    roundNumber = 1,
    status = 'in_progress',
    proposal = {},
    feedback = {},
  }) => {
    return {
      roundId,
      reviewId,
      roundNumber,
      status,
      proposal,
      feedback,
    } satisfies RoundDomainSelectModel
  }
)
