import { defined } from '@mntn-dev/utilities'
import { type AnyRoute, routeMap } from '../route.ts'

export const findRoute = (
  localPath: string | undefined
): AnyRoute | undefined => {
  if (localPath) {
    const route = Object.values(routeMap).find((route) =>
      route().isMatch(localPath)
    )
    return route ? route() : undefined
  }
}

export type FindRouteAncestryOptions = {
  includeSelf?: boolean
  excludeRoot?: boolean
}

export const findRouteAncestry = (
  route: AnyRoute | undefined,
  { includeSelf, excludeRoot }: FindRouteAncestryOptions = {}
): AnyRoute[] => {
  const routeAncestry = route
    ? defined([
        includeSelf ? route : undefined,
        ...route.segments.map((_, i) =>
          findRoute(
            `/${route.segments.slice(0, route.segments.length - 1 - i).join('/')}`
          )
        ),
      ]).reverse()
    : []

  return routeAncestry.slice(
    excludeRoot ? Math.min(1, routeAncestry.length) : 0
  )
}
