import { convertCentsToDollars } from './convert-cents-to-dollars.ts'
import { convertDollarsToCents } from './convert-dollars-to-cents.ts'

/**
 * WARNING: DO NOT MODIFY THIS FUNCTION - DOING SO WILL MODIFY APPROVED PROJECT PRICES
 *
 * Calculates an increased cost by the provided margin.
 *
 * Example: given cost=1000 and margin=50, the result is 2000 (I have to charge $2000 to make a 50% margin on $1000)
 *
 * @param cost the base cost that should be increased by the margin
 * @param marginPercent a number between 0-99 representing the percentage we should increase the cost
 * @returns the cost, increased by the amount we need to earn the provided margin%
 */
export const calculateCostPlusMargin = (
  cost: number,
  marginPercent: number
) => {
  const costInCents = convertDollarsToCents(cost)
  const percent = Math.min(Math.max(marginPercent, 0), 99) / 100
  return Math.round(convertCentsToDollars(costInCents / (1 - percent)))
}
