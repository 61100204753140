import {
  PackageId,
  ProjectId,
  ProjectStatuses,
  ProjectUrn,
  type ProjectViewDomainSelectModel,
  TeamId,
  UserId,
} from '@mntn-dev/domain-types'
import { calculateCostPlusMargin } from '@mntn-dev/finance'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'
import { MockPackage } from './mock-package.ts'

export const MockProject = MockModel<
  ProjectViewDomainSelectModel & { $package?: never }
>(
  ({
    projectId = ProjectId(),
    projectUrn = ProjectUrn(projectId),
    name = `Project ${projectId}`,
    description = `Project Description ${projectId}`,
    ownerId = UserId(),
    brandTeamId = TeamId(),
    status = getRandomItem(ProjectStatuses),
    costMarginPercent = 50,
    packageId = PackageId(),
    inherited = {
      package: MockPackage({ packageId, costMarginPercent }),
    },
    cost = inherited.package.cost,
    costPlusMargin = cost && calculateCostPlusMargin(cost, costMarginPercent),
    brandCompanyName = `Brand ${brandTeamId}`,
    lastTouchedTimestamp = new Date(),
  }) => {
    return {
      projectId,
      projectUrn,
      name,
      description,
      ownerId,
      brandTeamId,
      status,
      packageId,
      inherited: {
        package: {
          name: inherited.package.name,
          description: inherited.package.description,
          cost: inherited.package.cost,
          costPlusMargin: inherited.package.costPlusMargin,
        },
      },
      cost,
      costMarginPercent,
      costPlusMargin,
      brandCompanyName,
      lastTouchedTimestamp,
    } satisfies ProjectViewDomainSelectModel
  }
)
