import {
  DeliverableId,
  type ProofDomainSelectModel,
  ProofId,
  RoundId,
} from '@mntn-dev/domain-types'
import { MockModel } from './mock-model.ts'

export const MockProof = MockModel<ProofDomainSelectModel>(
  ({
    proofId = ProofId(),
    roundId = RoundId(),
    deliverableId = DeliverableId(),
    proposal = {},
    feedback = {},
  }) => {
    return {
      proofId,
      roundId,
      deliverableId,
      proposal,
      feedback,
    } satisfies ProofDomainSelectModel
  }
)
