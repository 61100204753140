import {
  OfferAcceptedReasons,
  OfferDeclinedReasons,
  OfferId,
  ProjectId,
  type SurveyDomainSelectModel,
  SurveyId,
  SurveyTypes,
  UserId,
} from '@mntn-dev/domain-types'
import {
  UnhandledUnionError,
  getRandomItem,
  makeRange,
  mapNonEmptyArray,
} from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

const getRandomRating = () =>
  getRandomItem(mapNonEmptyArray(makeRange(5), (i) => i + 1))

export const MockSurveyDetails = (): SurveyDomainSelectModel['details'] => {
  const surveyType = getRandomItem(SurveyTypes)

  switch (surveyType) {
    case 'offer-accepted': {
      const offerId = OfferId()

      return {
        surveyType,
        offerId,
        responses: {
          reason: getRandomItem(OfferAcceptedReasons),
          feedback: `Feedback for ${offerId} ${surveyType}`,
        },
      }
    }

    case 'offer-declined': {
      const offerId = OfferId()

      return {
        surveyType,
        offerId,
        responses: {
          reason: getRandomItem(OfferDeclinedReasons),
          feedback: `Feedback survey ${offerId} ${surveyType}`,
        },
      }
    }

    case 'project-completed-brand-reviews-maker': {
      const projectId = ProjectId()

      return {
        surveyType,
        projectId,
        responses: {
          finalVideoQualityRating: getRandomRating(),
          makerRating: getRandomRating(),
          feedback: `Feedback for ${projectId} ${surveyType}`,
        },
      }
    }

    case 'project-completed-maker-reviews-brand': {
      const projectId = ProjectId()

      return {
        surveyType,
        projectId,
        responses: {
          brandRating: getRandomRating(),
          feedback: `Feedback for ${projectId} ${surveyType}`,
        },
      }
    }

    default: {
      throw new UnhandledUnionError(surveyType)
    }
  }
}

export const MockSurvey = MockModel<SurveyDomainSelectModel>(
  ({ surveyId = SurveyId(), details = MockSurveyDetails() }) => {
    return {
      surveyId,
      responderId: UserId(),
      details,
    } satisfies SurveyDomainSelectModel
  }
)
