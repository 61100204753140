import {
  ChannelIds,
  DeliverableCategories,
  type DeliverableDomainSelectModel,
  DeliverableId,
  DeliverableReviewLevels,
  ProjectId,
  ProjectServiceId,
  VideoCutTypes,
} from '@mntn-dev/domain-types'
import { UnhandledUnionError, getRandomItem } from '@mntn-dev/utilities'

import { MockModel } from './mock-model.ts'

export const MockDeliverableDetails = (
  category = getRandomItem(DeliverableCategories)
): DeliverableDomainSelectModel['details'] => {
  switch (category) {
    case 'video': {
      return {
        category,
        cut: getRandomItem(VideoCutTypes),
        duration: getRandomItem([15, 30, 45]),
        channel: getRandomItem(ChannelIds),
      }
    }

    case 'file': {
      return { category }
    }

    case 'archive': {
      return { category }
    }

    default: {
      throw new UnhandledUnionError(category)
    }
  }
}

export const MockDeliverable = MockModel<DeliverableDomainSelectModel>(
  ({
    deliverableId = DeliverableId(),
    projectId = ProjectId(),
    projectServiceId = ProjectServiceId(),
    reviewLevel = getRandomItem(DeliverableReviewLevels),
    details = MockDeliverableDetails('video'),
  }) => {
    return {
      deliverableId,
      projectId,
      projectServiceId,
      details,
      reviewLevel,
    } satisfies DeliverableDomainSelectModel
  }
)
