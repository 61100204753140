import type {
  ProjectId,
  ProjectStatus,
  ServiceLike,
} from '@mntn-dev/domain-types'

export type CostLike = { cost?: number; costPlusMargin?: number }

export type ProjectLikeWithPricingfields = {
  projectId: ProjectId
  status: ProjectStatus
} & CostLike

export type ServiceLikeWithPricingFields = ServiceLike & CostLike

export const MakerPriceContext = 'agency'
export const BrandPriceContext = 'brand'
export type PriceContext = typeof MakerPriceContext | typeof BrandPriceContext

export const MakerPriceField = 'cost'
export const BrandPriceField = 'costPlusMargin'

export const MakerPriceContextWithField = {
  context: MakerPriceContext,
  field: MakerPriceField,
} as const

export const BrandPriceContextWithField = {
  context: BrandPriceContext,
  field: BrandPriceField,
} as const

export type PriceContextWithField =
  | typeof MakerPriceContextWithField
  | typeof BrandPriceContextWithField
