import {
  ActivityId,
  type NotificationDomainSelectModel,
  NotificationId,
  NotificationStatuses,
  UserId,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockNotification = MockModel<NotificationDomainSelectModel>(
  ({
    notificationId = NotificationId(),
    recipientId = UserId(),
    activityId = ActivityId(),
    status = getRandomItem(NotificationStatuses),
  }) => {
    return {
      notificationId,
      recipientId,
      activityId,
      status,
    } satisfies NotificationDomainSelectModel
  }
)
