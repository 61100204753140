import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const Auth0UserSchema = z.object({
  sub: z.string(),
  name: z.string().optional(),
  nickname: z.string().optional(),
  given_name: z.string().optional(),
  family_name: z.string().optional(),
  picture: z.string().optional(),
  email: z.string().optional(),
  email_verified: z.boolean().optional(),
  org_id: z.string().optional(),
})

export type Auth0User = ZodSimplify<typeof Auth0UserSchema>

export const Auth0SessionSchema = z.object({
  user: Auth0UserSchema,
  tokenSet: z.object({
    accessToken: z.string(),
    scope: z.string().optional(),
    refreshToken: z.string().optional(),
    expiresAt: z.number(),
  }),
  internal: z.object({
    sid: z.string(),
    createdAt: z.number(),
  }),
})

export type Auth0Session = ZodSimplify<typeof Auth0SessionSchema>
