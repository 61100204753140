import {
  OrganizationId,
  OrganizationTypes,
  type UserDomainSelectModel,
  UserId,
  UserUrn,
} from '@mntn-dev/domain-types'

import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockUser = MockModel<UserDomainSelectModel>(
  ({
    userId = UserId(),
    userUrn = UserUrn(userId),
    organizationId = OrganizationId(),
    emailAddress = `user+${userId}@example.com`,
    organizationType = getRandomItem(OrganizationTypes),
    firstName = `First${userId}`,
    lastName = `Last${userId}`,
    displayName = `${firstName} ${lastName}`,
    initials = `${firstName[0]}${lastName[0]}`.toUpperCase(),
    authConnection = 'email',
    isActive = true,
    signUpTimestamp = new Date(),
  }) => {
    return {
      userId,
      userUrn,
      organizationId,
      emailAddress,
      organizationType,
      firstName,
      lastName,
      displayName,
      initials,
      authConnection,
      isActive,
      signUpTimestamp,
    } satisfies UserDomainSelectModel
  }
)
