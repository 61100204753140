import type { Permissions } from '@mntn-dev/auth-types'

import type { FileArea } from '@mntn-dev/domain-types'
import type { PermissionContext } from './types.ts'

export const createPermissions = ({
  organizationType,
  isSystem,
}: PermissionContext): Permissions => {
  const isSystemUser = isSystem === true
  const isInternalUser = organizationType === 'internal'

  const isAdminUser = isInternalUser || isSystemUser

  const isBrandUser = organizationType === 'brand'
  const isMakerUser = organizationType === 'agency'

  const isAnyUser = isSystemUser || isInternalUser || isBrandUser || isMakerUser

  return {
    // Activity
    canCreateActivity: () => isAnyUser,
    canViewActivity: () => isAnyUser,

    // Agency
    canEditAgency: () => isAdminUser || isMakerUser,
    canViewAgency: () => isMakerUser || isAdminUser,

    // Brand
    canViewBrand: () => isBrandUser || isAdminUser,

    // Cost / Pricing
    canViewCost: () => isMakerUser || isAdminUser,
    canViewCostPlusMargin: () => isBrandUser || isAdminUser,
    canViewInternalPricingNote: () => isBrandUser || isAdminUser,

    // File
    canUploadFile: (_fileType: FileArea) => isAnyUser, // TODO: actually check if the user can upload the file

    // Internal Updates
    canSubscribeToInternalUpdates: () => isAdminUser,

    // Match
    canEditMatch: () => isInternalUser,
    canRemoveMatch: () => isInternalUser,
    canViewMatch: () => isInternalUser,

    // Offer
    canAcceptOffer: () => isMakerUser,
    canDeclineOffer: () => isMakerUser,
    canViewOffer: () => isMakerUser || isAdminUser,

    // Organizations
    canAdministerCustomerOrganizations: () => isAdminUser,
    canAdministerOwnOrganization: () => isAnyUser,

    // Packages
    canAdministerPackages: () => isAdminUser,
    canViewPackages: () => isBrandUser || isAdminUser,

    // Project
    canAdministerProjects: () => isAdminUser,
    canArchiveProject: () => isBrandUser,
    canCommentOnProject: () => isBrandUser || isMakerUser,
    canCompleteProject: () => isMakerUser || isAdminUser,
    canCreateProject: () => isBrandUser || isSystemUser,
    canDeleteProject: () => isBrandUser,
    canEditProject: () => isBrandUser || isAdminUser,
    canSubmitProject: () => isBrandUser || isSystemUser,
    canViewProject: () => isAnyUser,

    // Review
    canCreateReview: () => isSystemUser,
    canUpdateReviewFeedback: () => isBrandUser,
    canUpdateReviewProposal: () => isMakerUser,

    // Teams
    canAdministerCustomerTeams: () => isAdminUser,
    canAdministerOwnOrganizationTeams: () => isAnyUser,
    canAdministerOwnTeam: () => isAnyUser,

    // Users
    canAdministerCustomerUsers: () => isAdminUser,
    canAdministerOwnOrganizationUsers: () => isAnyUser,
    canAdministerOwnTeamUsers: () => isAnyUser,

    // Watch
    canAdministerWatches: () => isAdminUser,
    canCreateWatch: () => isSystemUser,
    canDeleteWatch: () => isSystemUser,
    canUpdateWatch: () => isSystemUser,
    canViewAllWatches: () => isSystemUser,
    canViewWatch: () => isSystemUser,

    // DEPRECATED
    canAdministerUsers: () => isAdminUser,
  }
}
