import { SessionId } from '@mntn-dev/domain-types'

import { getAuth0User } from '@mntn-dev/auth-types'
import { fromUnauthenticatedSession } from './manager.ts'
import { SystemPrincipal, SystemUser } from './system-session.ts'

export const AuthorizedSystemSession = () =>
  fromUnauthenticatedSession({
    sessionId: SessionId(),
    principal: SystemPrincipal,
    user: getAuth0User(SystemUser),
  }).authorize(SystemPrincipal)
