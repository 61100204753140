import { useMemo } from 'react'

import { getPermissions } from '@mntn-dev/authorization-types'
import { createPermissions } from '@mntn-dev/session-manager'
import { usePrincipal } from './use-principal.ts'

function usePermissions() {
  const { principal } = usePrincipal()

  // Merge the legacy permissions with the new permissions until we see where this goes
  // TODO: Remove all the legacy permission functions if/when it comes to it.
  const permissions = useMemo(
    () => ({
      ...createPermissions(principal),
      ...getPermissions(principal.privileges),
    }),
    [principal]
  )

  return permissions
}

export { usePermissions }
