import {
  PackageId,
  PackageServiceId,
  type PackageServiceViewDomainSelectModel,
  ServiceTypes,
} from '@mntn-dev/domain-types'
import { calculateCostPlusMargin } from '@mntn-dev/finance'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'
import { MockService } from './mock-service.ts'

export const MockPackageService =
  MockModel<PackageServiceViewDomainSelectModel>(
    ({
      packageServiceId = PackageServiceId(),
      packageId = PackageId(),
      serviceType = getRandomItem(ServiceTypes),
      count = 1,
      cost,
      costPlusMargin = cost && calculateCostPlusMargin(cost, 50),
    }) => {
      const { status, ...service } = MockService({ status: 'published' })

      return {
        packageServiceId,
        packageId,
        serviceType,
        count,
        cost,
        costPlusMargin,
        ...service,
      } satisfies PackageServiceViewDomainSelectModel
    }
  )
