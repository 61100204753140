import { routeMap } from '../route.ts'

export const getProtectedRouteRoots = () => {
  return Array.from(
    new Set(
      Object.entries(routeMap)
        .filter(
          ([routeKey, routeValue]) =>
            routeValue().isProtected &&
            !routeValue().isTest &&
            routeKey.length > 1
        )
        .map(([, routeValue]) => routeValue().getRoot())
    )
  )
}
