import type { OrganizationType } from '@mntn-dev/domain-types'
import { makeTuple } from '@mntn-dev/utilities'
import type { PositiveInteger } from '../../utility-types/src/utility-types.ts'
import { MockOrganization } from './mock-organization.ts'
import { MockTeam } from './mock-team.ts'
import { MockUser } from './mock-user.ts'

const MockTeamUsers =
  <SingleOrgType extends OrganizationType>(organizationType: SingleOrgType) =>
  <Count extends number = 1>(count?: PositiveInteger<Count>) => {
    const organization = MockOrganization({ organizationType })

    const team = MockTeam({
      organizationType,
      organizationId: organization.organizationId,
    })

    const users = makeTuple((count ?? 1) as PositiveInteger<Count>, () =>
      MockUser({
        organizationId: organization.organizationId,
        organizationType,
      })
    )

    return { organization, team, users }
  }

export const MockAgencyUsers = MockTeamUsers('agency')
export const MockBrandUsers = MockTeamUsers('brand')
export const MockInternalUsers = MockTeamUsers('internal')
