import type { RoutesTFunction, RoutesTKey } from '@mntn-dev/i18n'
import { hasProperty } from '@mntn-dev/utilities'
import type { AnyRoute } from '../route.ts'

export const hasNameAttributes = (attributes: unknown) =>
  hasProperty<'name', RoutesTKey>(attributes, 'name')

export const getNameAttribute = ({ route }: { route: AnyRoute }) =>
  hasNameAttributes(route.attributes) ? route.attributes.name : undefined

export const translateRouteName = ({
  route,
  t,
}: { route: AnyRoute; t: RoutesTFunction }) =>
  hasNameAttributes(route.attributes) ? t(route.attributes.name) : undefined
