import {
  TeamId,
  type TeamProfileDomainSelectModel,
  TeamProfileId,
} from '@mntn-dev/domain-types'
import { MockModel } from './mock-model.ts'

export const MockTeamProfile = MockModel<TeamProfileDomainSelectModel>(
  ({
    teamProfileId = TeamProfileId(),
    teamId = TeamId(),
    overview,
    emailAddresses = ['example@example.com'],
    websiteUrls,
    phoneNumbers,
  }) => {
    return {
      teamProfileId,
      teamId,
      overview,
      emailAddresses,
      websiteUrls,
      phoneNumbers,
    } satisfies TeamProfileDomainSelectModel
  }
)
