import type { TupleToUnion } from 'type-fest'
import type { Action } from './actions.ts'
import type { Resource } from './resource.ts'

export const Permissions = (<const>[
  'activity:create',
  'activity:view',

  'file:upload',

  'organization:administer',

  'cost:view',
  'cost-plus-margin:view',

  'project:view',
  'project:accept',
  'project:archive',
  'project:comment',
  'project:create',
  'project:delete',
  'project:edit',
  'project:submit',
  'project:complete',
  'project:administer',

  'offer:view',
  'offer:accept',
  'offer:decline',

  'team:administer',

  'user:administer',

  'package:view',
  'package:administer',

  'watch:administer',

  'match:edit',
  'match:remove',
  'match:view',

  'review:create',
  'review-feedback:update',
  'review-proposal:update',

  'internal-updates:subscribe',
  'internal-pricing-note:view',
]) satisfies readonly `${Resource}:${Action}`[]

export type Permission = TupleToUnion<typeof Permissions>

export const AllPermissions = [...Permissions]
