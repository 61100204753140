import {
  FileCategories,
  type FileDomainSelectModel,
  FileId,
  FileStorageKey,
  FileTaggingStatuses,
  FileUploadStatuses,
  ProjectUrn,
  UserId,
} from '@mntn-dev/domain-types'
import { getRandomItem } from '@mntn-dev/utilities'
import { MockModel } from './mock-model.ts'

export const MockFile = MockModel<FileDomainSelectModel>(
  ({
    fileId = FileId(),
    folderUrn = ProjectUrn(),
    area = 'projects.assets.reference',
    name = `File ${fileId}`,
    category = getRandomItem(FileCategories),
    size = 1_000_000,
    mimeType = 'video/mp4',
    status = 'active',
    storageKey = FileStorageKey(fileId),
    uploadStatus = getRandomItem(FileUploadStatuses),
    ownerId = UserId(),
    uploadTimestamp = new Date(),
    taggingStatus = getRandomItem(FileTaggingStatuses),
  }) => {
    return {
      fileId,
      folderUrn,
      area,
      name,
      category,
      size,
      mimeType,
      status,
      storageKey,
      uploadStatus,
      ownerId,
      uploadTimestamp,
      taggingStatus,
    } satisfies FileDomainSelectModel
  }
)
