import { PrivilegeKeySchema } from '@mntn-dev/authorization-types'
import {
  EmailAddressSchema,
  OrganizationIdSchema,
  OrganizationTypeSchema,
  TeamIdSchema,
  UserDomainSelectModelSchema,
  UserIdSchema,
} from '@mntn-dev/domain-types'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

// The principal as our service / database sees it.

export const PrincipalSchema = z.object({
  userId: UserIdSchema,
  emailAddress: EmailAddressSchema,
  privileges: PrivilegeKeySchema.array(),
  teamIds: z.array(TeamIdSchema),
  organizationId: OrganizationIdSchema,
  organizationType: OrganizationTypeSchema,
  user: UserDomainSelectModelSchema,
  isSystem: z.boolean().optional(),
})

export type Principal = ZodSimplify<typeof PrincipalSchema>

/**
 * The extended principal in the session object.
 * This can contain additional and externally provided information, like scopes and claims from the IdP access token.
 */

export const SessionPrincipalSchema = PrincipalSchema.extend({
  scopes: z.string().array(),
})

export type SessionPrincipal = ZodSimplify<typeof SessionPrincipalSchema>
