import { secondsToMilliseconds } from 'date-fns'

import type { UnauthenticatedSession } from '@mntn-dev/auth-types'
import { SessionManagerLogger } from './session-manager-logger.ts'

/**
 * Checks if a session has an expiration date and the expiration date is in the past.
 *
 * @param {Session} session - The session object which may or may not contain an access token expiration date.
 * @returns {boolean} - Returns true if the session is expired (i.e., the current date and time is greater than the access token's expiration date), otherwise false.
 */
export function sessionIsExpired(session: NonNullable<UnauthenticatedSession>) {
  if (session.accessTokenExpiresAt) {
    if (Date.now() > secondsToMilliseconds(session.accessTokenExpiresAt)) {
      const expirationTimestamp = session.accessTokenExpiresAt
      const expirationDate = new Date(
        secondsToMilliseconds(expirationTimestamp)
      )
      const expirationDateTime = expirationDate.toLocaleString()

      SessionManagerLogger.info('Session is expired', {
        session,
        expirationTimestamp,
        expirationDateTime,
      })
      return true
    }
  }
  return false
}
