import {
  ProjectId,
  type ReviewDomainSelectModel,
  ReviewId,
  ReviewUrn,
} from '@mntn-dev/domain-types'
import { MockModel } from './mock-model.ts'

export const MockReview = MockModel<ReviewDomainSelectModel>(
  ({
    reviewId = ReviewId(),
    reviewUrn = ReviewUrn(reviewId),
    reviewKey = { reviewType: 'post_production', projectId: ProjectId() },
    currentRoundNumber = 1,
    maxChangeRequests = 1,
    status = 'concepting',
  }) => {
    return {
      reviewId,
      reviewUrn,
      reviewKey,
      currentRoundNumber,
      maxChangeRequests,
      status,
    } satisfies ReviewDomainSelectModel
  }
)
